import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private localeSubject = new BehaviorSubject<string>(this.translate.currentLang || 'ro');

  locale$ = this.localeSubject.asObservable();

  constructor(private translate: TranslateService) {
    this.translate.onDefaultLangChange.subscribe(({ lang }) => {
      this.localeSubject.next(lang);
    });
    this.translate.onLangChange.subscribe(({ lang }) => {
      this.localeSubject.next(lang);
    });
  }
}
